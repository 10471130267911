.inln-date {
	.flatpickr-calendar {
		box-shadow: none;
		margin: 0 auto;
        padding: 0;
        background-color: inherit;
	}
	.dayContainer {
		width: 100%;
		min-width: auto;
	}
	.flatpickr-days {
		width: 100%;
	}
}

@media(max-width:320px){
    .flatpickr-innerContainer{
        padding-right: 40px;
    }
    .flatpickr-current-month{
        left: 6.5%;
        }
    .flatpickr-months {
        .flatpickr-next-month.flatpickr-next-month {
                padding-right: 46px;
            }
            .flatpickr-prev-month.flatpickr-next-month {
                padding-right: 46px;
            }
        }
    .bg-h-full {
        .mm-top-navbar.fixed {
            .navbar {
                padding: 0px !important;
            }
        }
    }
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {background: $primary; border-color: $primary;}