.mm-sidebar-menu .side-menu.collapsemenu{
    @media (min-width:1300px) {
      display: none !important;
    }
  }
  .mm-sidebar-menu .side-menu.hover{
    @media (max-width:1299px){
      display: none !important;
    }
  }
