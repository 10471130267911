@mixin bg-variant($parent, $color, $light-text: false) {
    #{$parent} {
      @if $light-text == true {
         color: darken($color, 50%) !important;
      } @else {
        color: color-yiq($color) !important;
      }
      background-color: $color !important;
    }
    a#{$parent},
    button#{$parent} {
      @include hover-focus() {
        @if($light-text){
          color: darken($color, 50%) !important;
        } @else {
          color: $white !important;
        }
        background-color: darken($color, 10%) !important;
      }
    }
  }

  @mixin bg-gradient-variant($parent, $color, $ignore-warning: false) {
    #{$parent} {
      background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
    }
  }