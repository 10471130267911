.scroller {
	overflow-y: auto;
	scrollbar-color: $primary $primary-light;
	scrollbar-width: thin;
	&::-webkit-scrollbar-thumb {
		background-color: $primary;
	}
	&::-webkit-scrollbar-track {
		background-color: $primary-light;
	}
	&::-moz-scrollbar-thumb {
		background-color: $primary;
	}
	&::-moz-scrollbar-track {
		background-color: $primary-light;
	}
}
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	border-radius: 20px;
}
::-moz-scrollbar {
	width: 4px;
	height: 4px;
	border-radius: 20px;
}
::-webkit-scrollbar-track {
	background: $border-color;
	border-radius: 20px;
}
::-webkit-scrollbar-thumb {
	background: $secondary;
	border-radius: 20px;
	&:hover {
		background: $dark;
		border-radius: 20px;
	}
}
::-moz-scrollbar-thumb {
	background: $secondary;
	border-radius: 20px;
	&:hover {
		background: $dark;
		border-radius: 20px;
	}
}
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius);
	}
}
::-moz-scrollbar {
	width: 4px;
	height: 4px;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius);
	}
}
.scrollbar-thumb {
	width: 4px !important;
}
.scrollbar-track-y {
	width: 4px !important;
}


body {
	font-family: $font-family-base;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: normal;
	padding: 0;
	margin: 0;
	color: $body-color;
	background: $body-bg;
	overflow-x: hidden;
}
a:hover{
	text-decoration: none;
}
h1 {
	font-family: $font-family-title;
	font-weight: $headings-font-weight;
	margin: 0px;
	// line-height: 1;
	color: $body-title;
	font-size: $h1-font-size;
	a {
		color: inherit;
	}
}
h2 {
	font-family: $font-family-title;
	font-weight: $headings-font-weight;
	margin: 0px;
	// line-height: 1.5;
	color: $body-title;
	font-size: $h2-font-size;
	a {
		color: inherit;
	}
}
h3 {
	font-family: $font-family-title;
	font-weight: $headings-font-weight;
	margin: 0px;
	// line-height: 1.5;
	color: $body-title;
	font-size: $h3-font-size
	a {
		color: inherit;
	}
}
h4 {
	font-family: $font-family-title;
	font-weight: $headings-font-weight;
	margin: 0px;
	// line-height: 1;
	color: $body-title;
	font-size: $h4-font-size;
	a {
		color: inherit;
	}
}
h5 {
	font-family: $font-family-title;
	font-weight: $headings-font-weight;
	margin: 0px;
	// line-height: 1.5;
	color: $body-title;
	font-size: $h5-font-size;
	a {
		color: inherit;
	}
}
h6 {
	font-family: $font-family-title;
	font-weight: $headings-font-weight;
	margin: 0px;
	// line-height: 1.5;
	color: $body-title;
	font-size: $h6-font-size;
	a {
		color: inherit;
	}
}
button:focus{
	outline: none;
}
.line-height {
    line-height: normal;
}
.rounded-normal,.rounded-small{
	@if $enable-rounded == true {
		@include radius-mixin($border-radius-normal) ;
	}
}
:focus{
	outline: none;
}
.text-break{
	word-break: break-all;
}
.border-none{
	border:none!important;
}
.breadcrumb-item+.breadcrumb-item {
		&::before {
			margin-top: 5px;
		}
}
.user-list-files{
	justify-content: flex-end;
	a {
    padding: 10px 15px;
    margin: 0 0 0 10px;
    border-radius: 5px;
    line-height: normal;
	}	
} 
code{
	color: $danger;
}
.slick-loading {
	.slick-list{
		background: transparent !important;
	}
}
.change-mode{
	margin-right: 25px;
	.custom-switch.custom-switch-icon{
		.custom-control-input {
			&:checked~.custom-control-label {
				&:after {
					transform: translateX(1.6rem);
				}
				&:before{
					background-color: $dark-body-bg;
				}
			}
			height: 100%;			
		}
		
		label.custom-control-label{
			height: 30px;
			&:after{
				top: 0;
				left: 0;
				width: 35px;
				height: 30px;
				@if $enable-rounded == true {
					@include radius-mixin(5px 0 0 5px);
				}
				background-color: $primary;
				border-color: $primary;
				z-index: 0;
			}
			&:before{
				width: 70px;
				height: 30px;
				top:0;
				left:0;
				background-color: $white;
				border-color: $primary;
				@include radius-mixin($border-radius-normal);				
			}
			.switch-icon-left{
				top:1px;
				z-index: 1;
				left: 10px;
				i{
					font-size: 12px;
				}
			}
			.switch-icon-right{
				color: $primary;
				right:-22px;
				top:2px;
				i{
					font-size: 12px;
				}
			}
		}
	}
}
.hospi-wrapper {
	.change-mode {
		.custom-switch {
			&.custom-switch-icon {
				label {
					&.custom-control-label {
						&:before {
							border-color: $white;
							background-color: $primary;
						}
						&:after {
							background-color: $white;
						}
					}
					.switch-icon-left {
						color: $primary;
					}
					.switch-icon-right {
						color: $white;
					}
				}
				.custom-control-input {
					&:checked~.custom-control-label {
						&:before{
							background-color: $primary;
							border-color:$white;
						}
						.switch-icon-left {
							color: $white;
						}
						.switch-icon-right {
							color: $primary;
						}
					}		
				}
			}
		}
	}
}
.custom-control-input {
	&:focus~.custom-control-label {
		&:before {
			box-shadow: none!important;
		}
	}
}
g[aria-describedby="id-22-description"] {
    stroke: none;
}
.ui-elements {
	.btn {
		margin-right: 10px;
	}
}
@media (min-width:1400px) {
	.container {
		max-width: 1300px;
	}
	.container-lg {
		max-width: 1300px;
	}
	.container-md {
		max-width: 1300px;
	}
	.container-sm {
		max-width: 1300px;
	}
	.container-xl {
		max-width: 1300px;
	}
}
@media (max-width: 1300px){
 .flatpickr-rContainer{
            padding-right: 40px;
        }
        .flatpickr-months {
            .flatpickr-next-month {
                padding-right: 40px;
            }
            .flatpickr-prev-month {
                padding-right: 40px;
            }
        }
        .flatpickr-current-month {
	        left: 7.5%;
        }
}
@media (max-width: 767px){
	.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: block;
    overflow-x: auto;
}
.tree{
	display: inline-table;
}

}
@media (max-width: 575px){
	.user-list-files {
		justify-content: center;
		margin-top:15px;
	}
}
.svg-icon{
    fill: rgba($primary-light, .6);
}

.svg-white {
	fill: $white;
}
.svg-danger {
	fill: rgba($danger-light, .4);
}
.svg-success {
	fill: rgba($success-light, .4);
}
.svg-primary {
	fill: rgba($primary-light, .4);
}
.svg-blue {
	fill: rgba($blue-300, .4);
}
.svg-warning {
	fill: rgba($warning-light, .4);
}
.svg-info {
	fill: rgba($info-light, .4);
}
.fill-none {
	fill: none !important;
}