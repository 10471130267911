.vactormap-height {
	width: 100%;
	height: 185px;
}
.jvectormap-legend-title {
	font-size: 12px;
}
.jvectormap-legend {
	.jvectormap-legend-tick-sample {
		height: 26px;
	}
}
.jvectormap-legend-icons {
	background: white;
	border: black 1px solid;
	color: black;
}
