//
// Dropdown
//

// General styles

.dropdown,
.dropup,
.dropright,
.dropleft {
    display: inline-block;
    cursor: pointer;
}

.dropdown-menu {
    min-width: 12rem;
    padding: .4rem 0;
    box-shadow: $dropdown-box-shadow;
    .dropdown-item {
        padding: .4rem 1rem;
        font-size: $font-size-sm;
        color: $body-text;
        &:hover{
            color: $primary;
            background: rgba($primary, .1);
        }
        > i,
        > svg {
            margin-right: 1rem;
            font-size: 1rem;
            vertical-align: -17%;
        }

        img {
            margin-right: .5rem;
        }
    }
}

.dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
    // color: $gray-100;
    font-size: .625rem;
    text-transform: uppercase;
    font-weight: 700;
}


// Media components inside dropdown link

.dropdown-menu {
    a.media {

        > div {
            &:first-child {
                line-height: 1;
            }
        }

        p {
            color: $gray-600;
        }

        &:hover {
            .heading,
            p {
                color: theme-color("default") !important;
            }
        }
    }
}

.dropdown-menu-dark {
    .h1, .h2, .h3, .h4, .h5, .h6, a {
        color: white;
    }
}

// Size variations

.dropdown-menu-sm {
    min-width: 100px;
    border: $border-radius-lg;
}

.dropdown-menu-lg {
    min-width: 320px;
    border-radius: $border-radius-lg;
}

.dropdown-menu-xl {
    min-width: 420px;
    border-radius: $border-radius-lg;
}
.dropdown-item {
    @include before-border-left();
    &:hover {
        border-radius: $dropdown-item-border-radius;
    }
}
.dropdown-menu {
    animation: fade 300ms ease forwards;

}
@keyframes fade {
    0% { 
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}