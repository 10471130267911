$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;
$sidemenu-with-space:                         false !default;

// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #ccced2 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #01041b !default;
$gray-900: #212529 !default;
$black:    #000 !default;


$grays: () !default;

// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$primary-300: #c9dcff !default;
$primary:    #33A74D !default;
$primary-800:  #1360ee !default;

$danger-300:     #f8c1cc !default;
$danger:     #f75676 !default;
$danger-800:     #ed2e54 !default;

$warning-300: #ffbbab !default;
$warning: #fc7c5f !default;
$warning-800: #e25a3b !default;

$blue-300: #c9dcff !default;
$blue:    #33A74D !default;
$blue-800:  #1360ee !default;
$blue-secondary:  #66b5f8 !default;

$red-300:     #ffb3c2 !default;
$red:     #f5365c !default;
$red-800:     #c01f3f !default;

$yellow-300:  #fff0a4 !default;
$yellow:  #fdc745 !default;
$yellow-800:  #c7a600 !default;

$green-300:   #aafad7 !default;
$green:   #4fd69c !default;
$green-800:   #2eba7d !default;

$cyan-300:    #adf1ff !default;
$cyan:    #37d5f2 !default;
$cyan-800:   #28a8bf !default;

$gray-300: #f1f2f1 !default;
$gray-500: #a09e9e !default;
$gray-800: #343a40 !default;

$orange-300:  #ffc4a0 !default;
$orange:  #fe721c !default;
$orange-800:  #e86514 !default;

$light: #c7cbd3  !default;

$purple-300:  #cbc0ff !default;
$purple:      #4731b6 !default;
$purple-800:  #9e8aff !default;

$skyblue-300:    #aad7ff !default;
$skyblue:        #158df7 !default;
$skyblue-800:    #117ede !default;

$pinkdark-300:    #FFF1F1 !default;
$pinkdark:        #FF6265 !default;
$pinkdark-800:    #2B2626 !default;


$indigo:  #6610f2 !default;
$teal:    #20c997 !default;

// color darkmode

$dark-body-bg: #222222 !default;
$dark-body-text :#EFF0F1 !default;
$dark-title-text : #ffffff !default;
$dark-border-color: #252525 !default;
$dark-card-bg:  #181818 !default;

//  color light mode

$bodytextdark: #595a5d !default;

$gray-light : #eff1fe !default;
$gray-dark1 : #16171d !default;
$gray-dark-hover:#191a20 !default;

$body-text :        #535f6b !default;
$title-text :       #303030 !default;
$body-bg:           #f7f9ff !default;

$border-color :     #deeaff !default;

$card-bg:           $white !default;
$card-border-width: 1px;
$card-border-color: #dfe7ff;
$card-cap-bg: $card-bg !default;


$startColor : #ffffff;
$endColor : #ffffff ;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "skyblue":    $skyblue,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800,

  ),
  $colors
);
$primary-light:     $primary-300 !default;
$primary:           $primary !default;
$primary-dark:      $primary-800 !default;
$secondary-light:   $gray-300 !default ;
$secondary:         $yellow !default;
$secondary-hover:   $gray-600 !default;
$secondary-button:  $blue-secondary !default;
$secondary-dark :   $gray-800 !default;
$success-light:     $green-300 !default;
$success:           $green !default;
$success-dark:      $green-800 !default;
$info-light:        $cyan-300 !default;
$info:              $cyan !default;
$info-dark:         $cyan-800 !default;
$warning-light:     $warning-300 !default;
$warning:           $warning !default;
$warning-dark:      $warning-800 !default;
$danger-light:      $red-300 !default;
$danger:            $red !default;
$danger-dark:       $red-800 !default;
$light:             $light !default;
$dark-light:        $gray-300 !default;
$dark:              $gray-800 !default;
$orange-light:      $orange-300 !default;
$orange:            $orange !default;
$orange-dark:       $orange-800 !default;
$purple-light:      $purple-300 !default;
$purple:            $purple !default;
$purple-dark:       $purple-800 !default;
$skyblue-light:     $skyblue-300 !default;
$skyblue:           $skyblue !default;
$skyblue-dark:      $skyblue-800 !default;
$pinkdark-light:    $pinkdark-300 !default;
$pinkdark:          $pinkdark !default;
$pinkdark-dark:     $pinkdark-800 !default;

$intro-light-bg: #fafbfe !default;
$intro-dark-bg: #2b343b !default;

$navbar-bg: $card-bg;
$navbar-bg-dark: $dark-card-bg;

$navbar-border-color: $border-color;

$sidebar-bg:        $card-bg;
$sidebar-bg-dark:        $dark-card-bg;

$footer-bg:        $card-bg;
$footer-bg-dark:        $dark-card-bg;



$header-submenu-color: $card-bg;
$header-submenu-border-border: 1px;
$header-submenu-border-color: $border-color;


$body-text-dark:    $bodytextdark !default;

$dark-primary:     rgba(71, 136, 255, 0.1);
$dark-secondary:   rgba(108, 117, 125, 0.1);
$dark-success:     rgba(55, 230, 178, 0.1);
$dark-info:       rgba(135, 108, 254, 0.1);
$dark-warning:    rgba(254, 114, 28, 0.1);
$dark-danger:     rgba(255, 75, 75, 0.1);
$dark-light:      rgba(199, 203, 211, 0.1);
$dark-dark:       rgba(1, 4, 27, 0.1);
$dark-orange:     rgba(253, 126, 20, 0.1);
$dark-purple:     rgba(71, 49, 182, 0.1);


$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "orange":     $orange,
    "purple":     $purple,
    "skyblue":    $skyblue,


  ),
  $theme-colors
);

$theme-custom: () !default;

$theme-custom: map-merge(
  (
    "primary":    $primary-light,
    "secondary":  $secondary-light,
    "success":    $success-light,
    "info":       $info-light,
    "warning":    $warning-light,
    "danger":     $danger-light,
    "light":      $light,
    "dark":       $dark-light,
    "orange":     $orange-light,
    "purple":     $purple-light,
    "skyblue":    $skyblue-light,
  ),
  $theme-custom
);

$dark-theme-custom: () !default;

$dark-theme-custom: map-merge(
  (
    "primary":    $dark-primary,
    "secondary":  $dark-secondary,
    "success":    $dark-success,
    "info":       $dark-info,
    "warning":    $dark-warning,
    "danger":     $dark-danger,
    "light":      $dark-light,
    "dark":       $dark-dark,
    "orange":     $dark-orange,
    "purple":     $dark-purple,
  ),
  $dark-theme-custom
);

$avatar-size: () !default;
$avatar-size: map-merge(
  (
    "30": 30,
    "35": 35,
    "40": 40,
    "45": 45,
    "50": 50,
    "60": 60,
    "70": 70,
    "80": 80,
    "90": 90,
    "100": 100,
    "110": 110,
    "120": 120,
    "130":130,
    "155": 155,
    "235":235

  ), $avatar-size
);

$font-family-title: 'Heebo', sans-serif;
$font-family-base: 'Poppins', sans-serif;
$headings-font-weight: 500;
$btn-font-family: $font-family-base;

$font-family-remixicon: remixicon;
$font-family-ionicons: Ionicons;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $body-bg !default;
$body-color:                $body-text !default;
$body-title:                $title-text !default;

// Links
//
// Style anchor elements.

$link-color:                              theme-color("primary") !default;
$link-decoration:                         none !default;
$link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration:                   none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;
$border-color:                #efefef !default;


$border-radius-normal:        4px !default;
$border-radius:               4px !default;
$border-radius-lg:            4px !default;
$border-radius-sm:            4px !default;
$border-radius-circle:        50% !default;
$border-radius-rounded:       50px !default;

$border-radius-top:       $border-radius $border-radius 0 0 !default;
$border-radius-right:    0 $border-radius $border-radius 0 !default;
$border-radius-left:        $border-radius 0 0 $border-radius !default;
$border-radius-bottom:     0 0 $border-radius $border-radius !default;

$border-radius-tl:      $border-radius 0 0 0 !default;
$border-radius-tr:      0 $border-radius 0 0 !default;
$border-radius-br:      0 0 $border-radius 0 !default;
$border-radius-bl:      0 0 0 $border-radius !default;


$rounded-pill:                $border-radius-rounded !default;

$box-shadow-sm:               none !default;
$box-shadow:                  none !default;
$box-shadow-lg:               none !default;

$btn-box-shadow:              none !default;
$btn-hover-box-shadow:        none !default;
$btn-focus-box-shadow:        $btn-hover-box-shadow !default;




$dropdown-bg:                    $white !default;
$dropdown-border-width:          0px !default;
$dropdown-border-color:          rgba($black, .15) !default;
$dropdown-border-radius:         $border-radius-lg !default;
$dropdown-box-shadow:            0 0 30px 4px rgba(71,135,255,0.1) !default;

$dropdown-link-color:            $gray-900 !default;
$dropdown-link-bg:               $white !default;

$dropdown-link-hover-color:      darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:         $gray-100 !default;

$dropdown-link-active-color:     $dropdown-link-hover-color !default;
$dropdown-link-active-bg:        transparent !default;

$dropdown-item-padding-y:        .5rem !default;
$dropdown-item-padding-x:        1rem !default;
$dropdown-item-border-radius: $border-radius-lg !default;


$font-size-xs: 12;
$font-size-sm: 12;
$font-size-md: 12;
$font-size-lg: 12;
$font-size-xl: 12;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:              16 !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;
// type-scale.com Minor thired base 16
$h1-font-size:                2.488rem !default;
$h2-font-size:                2.074rem !default;
$h3-font-size:                1.728rem !default;
$h4-font-size:                1.44rem !default;
$h5-font-size:                1.2rem !default;
$h6-font-size:                1.0rem !default;



// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  200 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;

$faq-font-icon-family: $font-family-remixicon;
$faq-font-icon-content-plus: '\EA13';
$faq-font-icon-content-minus: '\F1AF';

$line-height-base:            1.5 !default;
$shape-height-base:           1.5 !default;

$component-active-color:        $white !default;
$component-active-bg:           theme-color("primary") !default;
$component-active-border-color: theme-color("primary") !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .625rem !default;
$input-btn-padding-x:         .9375rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .875rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            $input-btn-border-width !default;

// $btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Alert

$alert-padding-y:                   .55rem !default;
$alert-padding-x:                   1.0rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-border-width:                $border-width !default;

$alert-bg-level:                    -10 !default;
$alert-border-level:                2 !default;
$alert-color-level:                 6 !default;

// Dropdown