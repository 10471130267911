.mm-hotel {
	.h-logo-ic {
		width: 120px;
	}
	.mm-top-navbar {
		background: transparent;
		position: static;
		width: calc(100% - 60px);
		margin: 30px;
		padding:0px;
        @if $enable-rounded == true {
            @include radius-mixin($border-radius);
        }
		.mm-user-dropdown {
			z-index: 9;
		}
	}
	.content-page {
		padding: 0 15px 0px;
		margin-left: 0;
	}
	.mm-footer {
		width: calc(100% - 60px);
		margin: 0 30px;
		padding: 15px 5px;
        @if $enable-rounded == true {
            @include radius-mixin($border-radius);
        }

		background: $white;
	}
	
	.mm-navbar-logo {
		>a {
			display: flex;
		}
	}
}
body.mm-hotel {
	.mm-sidebar {
		position: absolute;
		z-index: 1000;
		top: 0;
		width: 0;
		height: 100%;
		bottom: 0; 
		left: 0;
	}
	.mm-sidebar-menu {
		.side-menu {
			li.active {
				>a {
					color: $primary;
				}
			}
		}
	}

}
.container.hotel-container {
	@if $enable-rounded == true {
        @include radius-mixin($border-radius);
    }
	position: relative;
	padding: 0 0 30px 0;
}

body.mm-hotel.header-style-1 {
	.main-circle {
		i {
			color: $primary;
		}
	}
}
.th-line {
	color: $primary;
}
.f-right {
	text-align: right;
}
.icon-small {
    height: 25px;
    width: 25px;
    line-height: 26px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
#hotel-chart-3 {
    min-height: 170px !important;
}
#hotel-payment-01 {
	.apexcharts-canvas {
		margin: 0 auto;
	}
}
#hotel-chart-6{
	svg{
		text{
			font-family: 'Roboto', sans-serif !important;
			font-weight: 500 !important;
		}
	}
}
#hotel-column-1{
	color: $body-text;
}
#hotel-chart-4{
	color: $body-text;
}
#dash-chart-04{
	min-height: 250px !important;
	.apexcharts-legend.position-top.center {
		justify-content: center;	
	}
	.apexcharts-legend.position-bottom.center {
		justify-content:center;
		inset: auto 0px 0px 55px !important;
	}
}
@media (min-width:1300px){
	.mm-hotel {
	.mm-top-navbar {
		.mm-navbar-logo{
			display: flex !important;
		}
		.side-menu-bt {
			.wrapper-menu {
				display: none !important;
			}
			display: block !important;
			padding: 0;
			margin: 0;
		}
	}
	
	.mm-page-menu-horizontal {
		.content-page {
			margin-left: 0px;
			background: $body-bg;
			position: relative;
			padding: 100px 50px 0px;
		}
	}
}


}
@media (max-width: 1299px){
     body.mm-hotel.sidebar-main {
		.content-page {
			padding: 0 15px 0px;
		}
		.mm-top-navbar {
			width: calc(100% - 60px);
		}
	}


.mm-hotel {
	.mm-sidebar-logo{
		padding: 15px 0px;
	}
	.menu-horizontal{
		left: 30px;
		top: 30px;
		z-index: 99;
		.mm-sidebar-menu {
			.side-menu {
				li {
					a {
						padding: 15px 0px 15px 0px;
					}
				}
			}
		}
		
	}
	.mm-navbar-custom.container {
		max-width: 100%;
	}
	.mm-top-navbar {
		.side-menu-bt {
			padding: 0;
		}
	}

	
}


}

@media (max-width: 991px){
    .mm-hotel {
		.mm-top-navbar {
			position: relative;	
			padding: 16px 0px !important;		
		}
		.menu-horizontal {
			left: 0;
			top: 0;
		}
	.navbar-collapse {
		z-index: 9;
	}

}
.sidebar-1{
	.mm-top-navbar{
		.navbar{
			padding:11px 0!important;
		}
		
	}
}

.f-left {
	text-align: center;
}
.f-right {
	text-align: center;
}
.hotel-container {
	.navbar {
		position: static;
	}
}

}
@media (max-width:767px){
	body.mm-hotel.sidebar-main{
		.content-page {
			padding: 0;
		}
	}
	body.mm-hotel.sidebar-main {
	.mm-top-navbar {
		width: calc(100% - 30px);
	}
}

	body.mm-hotel{		
		
		.mm-top-navbar {
			width: calc(100% - 30px);
    		margin: 15px;
			padding: 15px;
		}
		.content-page{
			padding: 0;
		}
		.mm-footer{
			width: calc(100% - 30px);
    		margin: 0 15px;
		}
	}


}
@media (max-width: 391px){
    .mm-hotel {
		.navbar-list {
			li {
				>a {
					padding: 0 6px;
				}
			}
		}
	}
	.mm-top-navbar{
		.mm-navbar-logo{
			img {
    			height: 31px;
			}
		} 
	} 

}


// animation
@-webkit-keyframes circle-small-scale {
	0% {
		-webkit-transform: scale(1.0);
	}
	100% {
		-webkit-transform: scale(1.1);
	}
}
@keyframes circle-small-scale {
	0% {
		transform: scale(1.0);
	}
	100% {
		transform: scale(1.1);
	}
}

.main-animation {
	background: $body-bg;
	overflow: hidden;
	height: 100vh;
	width: 100%;
	margin: 0px auto;
	padding: 0px auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

#animation-inside {
	position: relative;
	min-width: 960px;
	max-width: 1480px;
	height: auto;
	min-height: 100%;
	margin: 0px auto;
	padding: 0px auto;
	overflow: visible;
	#circle-small {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0s;
	position: absolute;
	top: -150px;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: $primary;
	width: 300px;
	height: 300px;
	border-radius: 50%;
	opacity: 0.4;
	}
	#circle-medium {
		-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation-timing-function: cubic-bezier(.6, 0, .4, 1);
		animation-delay: 0.3s;
		position: absolute;
		top: -300px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background:$primary;
		width: 600px;
		height: 600px;
		border-radius: 50%;
		opacity: 0.3;
	}
	#circle-large {
		-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation-timing-function: cubic-bezier(.6, 0, .4, 1);
		animation-delay: 0.6s;
		position: absolute;
		top: -450px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: $primary;
		width: 900px;
		height: 900px;
		border-radius: 50%;
		opacity: 0.2;
	}
	#circle-xlarge {
		-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation-timing-function: cubic-bezier(.6, 0, .4, 1);
		animation-delay: 0.9s;
		position: absolute;
		top: -600px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: $primary;
		width: 1200px;
		height: 1200px;
		border-radius: 50%;
		opacity: 0.1;
	}
	#circle-xxlarge {
		-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation: circle-small-scale 3s ease-in-out infinite alternate;
		animation-timing-function: cubic-bezier(.6, 0, .4, 1);
		animation-delay: 1.2s;
		position: absolute;
		top: -750px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: $primary;
		width: 1500px;
		height: 1500px;
		border-radius: 50%;
		opacity: 0.05;
	}
}

