.loading-general-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
  opacity: 0.9;
  z-index: 9999;
}

.loading-steps__step:before, .loading-steps__icon {
	display: inline-block;
	font-size: 5em;
	height: 1.5em;
	width: 1.5em;
	line-height: 1;
}

.loading-steps__step:before, .loading-steps__icon {
	position: absolute;
	left: 50%;
	margin-left: -0.75em;
	top: 0;
}

.loading-steps {
	margin: 1rem auto;
	position: relative;
	display: flex;
	font-size: 1rem;
	height: 12.25em;
	width: 25em;
}

.loading-steps__step {
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	align-items: flex-start;
	justify-content: center;
}

.loading-steps__step:before {
	content: "";
	border-radius: 99em;
	z-index: -999;
	background: rgba(0, 0, 0, 0.1);
	transform: rotate(360deg);
}

.loading-steps__icon {
	padding: 0.25em;
	opacity: 0;
	color: black;
}

.loading-steps__icon-content {
	font-size: inherit;
	animation: 2.5s loading-steps__wobble ease-in-out infinite;
}

.loading-steps__text {
	margin-top: 8.5em;
	font-size: 1.25em;
	display: block;
	width: 100%;
	text-align: center;
	opacity: 0;
	color: black;
}

.loading-steps__icon, .loading-steps__text {
	animation: 5s loading-steps__animate-fade ease-in-out infinite, 5s loading-steps__animate-slide ease-in-out infinite, 5s loading-steps__animate-zoom ease-in-out infinite;
}

@keyframes loading-steps__animate-zoom {
	5% {
		transform-origin: center;
		transform: scale(0.5);
	}

	15%,
  90% {
		transform-origin: center;
		transform: scale(1);
	}

	95% {
		transform-origin: center;
		transform: scale(0.5);
	}
}

@keyframes loading-steps__animate-fade {
	5% {
		opacity: 0;
	}

	10%,
  90% {
		opacity: 1;
	}

	95% {
		opacity: 0;
	}
}

@keyframes loading-steps__animate-slide {
	0% {
		left: 100%;
	}

	8%,
  92% {
		left: 50%;
	}

	100% {
		left: -50%;
	}
}

@keyframes loading-steps__wobble {
	33% {
		transform: rotate(15deg);
	}

	66% {
		transform: rotate(-15deg);
	}
}

.loading-steps__step {
	opacity: 0;
}

.loading-steps__step:nth-child(1) {
	animation: 30s loading-steps__display-steps-over-time ease-in-out infinite;
	animation-delay: 0s;
}

.loading-steps__step:nth-child(2) {
	animation: 30s loading-steps__display-steps-over-time ease-in-out infinite;
	animation-delay: 5s;
}

.loading-steps__step:nth-child(3) {
	animation: 30s loading-steps__display-steps-over-time ease-in-out infinite;
	animation-delay: 10s;
}

.loading-steps__step:nth-child(4) {
	animation: 30s loading-steps__display-steps-over-time ease-in-out infinite;
	animation-delay: 15s;
}

.loading-steps__step:nth-child(5) {
	animation: 30s loading-steps__display-steps-over-time ease-in-out infinite;
	animation-delay: 20s;
}

.loading-steps__step:nth-child(6) {
	animation: 30s loading-steps__display-steps-over-time ease-in-out infinite;
	animation-delay: 25s;
}

@keyframes loading-steps__display-steps-over-time {
	0% {
		opacity: 1;
	}

	16.65667% {
		opacity: 1;
	}

	16.66667% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

.loading-steps__bar {
	width: 100%;
	height: 0.5em;
	position: absolute;
	top: 7em;
	font-size: 1.25em;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 99em;
	margin: 0.25em;
}

.loading-steps__bar:before {
	content: "100%";
	display: table;
	vertical-align: middle;
	position: absolute;
	bottom: 50%;
	left: 100%;
	transform: translate(0, 50%) rotate(360deg);
	text-align: center;
	font-family: monospace;
	font-size: 0.5em;
	line-height: 3em;
	width: 3em;
	height: 3em;
	color: white;
	margin-left: -0.25em;
	background: black;
	border-radius: 99em 99em;
	z-index: 10;
	animation: percentage 120s linear 1, loading-text-offset 120s linear 1;
}

.loading-steps__bar:after {
	content: "";
	display: inline-block;
	position: absolute;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	border-radius: 99em 0 0 99em;
	background-color: black;
	animation: loading 120s linear 1;
}

@keyframes loading {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

@keyframes loading-text-offset {
	0% {
		left: 0;
	}

	100% {
		left: 100%;
	}
}

@keyframes percentage {
	1% {
		content: "1%";
	}

	2% {
		content: "2%";
	}

	3% {
		content: "3%";
	}

	4% {
		content: "4%";
	}

	5% {
		content: "5%";
	}

	6% {
		content: "6%";
	}

	7% {
		content: "7%";
	}

	8% {
		content: "8%";
	}

	9% {
		content: "9%";
	}

	10% {
		content: "10%";
	}

	11% {
		content: "11%";
	}

	12% {
		content: "12%";
	}

	13% {
		content: "13%";
	}

	14% {
		content: "14%";
	}

	15% {
		content: "15%";
	}

	16% {
		content: "16%";
	}

	17% {
		content: "17%";
	}

	18% {
		content: "18%";
	}

	19% {
		content: "19%";
	}

	20% {
		content: "20%";
	}

	21% {
		content: "21%";
	}

	22% {
		content: "22%";
	}

	23% {
		content: "23%";
	}

	24% {
		content: "24%";
	}

	25% {
		content: "25%";
	}

	26% {
		content: "26%";
	}

	27% {
		content: "27%";
	}

	28% {
		content: "28%";
	}

	29% {
		content: "29%";
	}

	30% {
		content: "30%";
	}

	31% {
		content: "31%";
	}

	32% {
		content: "32%";
	}

	33% {
		content: "33%";
	}

	34% {
		content: "34%";
	}

	35% {
		content: "35%";
	}

	36% {
		content: "36%";
	}

	37% {
		content: "37%";
	}

	38% {
		content: "38%";
	}

	39% {
		content: "39%";
	}

	40% {
		content: "40%";
	}

	41% {
		content: "41%";
	}

	42% {
		content: "42%";
	}

	43% {
		content: "43%";
	}

	44% {
		content: "44%";
	}

	45% {
		content: "45%";
	}

	46% {
		content: "46%";
	}

	47% {
		content: "47%";
	}

	48% {
		content: "48%";
	}

	49% {
		content: "49%";
	}

	50% {
		content: "50%";
	}

	51% {
		content: "51%";
	}

	52% {
		content: "52%";
	}

	53% {
		content: "53%";
	}

	54% {
		content: "54%";
	}

	55% {
		content: "55%";
	}

	56% {
		content: "56%";
	}

	57% {
		content: "57%";
	}

	58% {
		content: "58%";
	}

	59% {
		content: "59%";
	}

	60% {
		content: "60%";
	}

	61% {
		content: "61%";
	}

	62% {
		content: "62%";
	}

	63% {
		content: "63%";
	}

	64% {
		content: "64%";
	}

	65% {
		content: "65%";
	}

	66% {
		content: "66%";
	}

	67% {
		content: "67%";
	}

	68% {
		content: "68%";
	}

	69% {
		content: "69%";
	}

	70% {
		content: "70%";
	}

	71% {
		content: "71%";
	}

	72% {
		content: "72%";
	}

	73% {
		content: "73%";
	}

	74% {
		content: "74%";
	}

	75% {
		content: "75%";
	}

	76% {
		content: "76%";
	}

	77% {
		content: "77%";
	}

	78% {
		content: "78%";
	}

	79% {
		content: "79%";
	}

	80% {
		content: "80%";
	}

	81% {
		content: "81%";
	}

	82% {
		content: "82%";
	}

	83% {
		content: "83%";
	}

	84% {
		content: "84%";
	}

	85% {
		content: "85%";
	}

	86% {
		content: "86%";
	}

	87% {
		content: "87%";
	}

	88% {
		content: "88%";
	}

	89% {
		content: "89%";
	}

	90% {
		content: "90%";
	}

	91% {
		content: "91%";
	}

	92% {
		content: "92%";
	}

	93% {
		content: "93%";
	}

	94% {
		content: "94%";
	}

	95% {
		content: "95%";
	}

	96% {
		content: "96%";
	}

	97% {
		content: "97%";
	}

	98% {
		content: "98%";
	}

	99% {
		content: "99%";
	}

	100% {
		content: "100%";
	}
}