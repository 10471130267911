
.clear { clear: both; }
@media (min-width: 1300px){
	.menu-horizontal{
		.mm-sidebar-menu{
			li{
				.mm-submenu.mm-submenu-data{
					position: static;
				}
			}
		}
	}
    .menu-horizontal {
		.mm-sidebar-menu {
			.mm-sidebar-logo{
				display: none !important;
			}
			.side-menu {
				li {
					&:first-child {
						a {
							padding-left: 0px;
						}
					}
					a {
						&:hover {
							color: $primary;
						}
					}
					li {
						.mm-arrow-right {
							@include transform-mixin( rotate(0deg));
						}
					}
					ul {
						display: block;
						position: absolute;
						top: 100%;
						padding: 10px 0px;
						min-width: 200px;
						background: $white;
						z-index: 9;
						@if $enable-transitions == true {
							@include transition-mixin(all 0.3s ease-in-out);
						}
						display: none;
						animation: fade-in-bottom .6s cubic-bezier(.39,.575,.565,1) both;
						@include transform-mixin( translateY(100%));
						@if $enable-rounded == true {
							@include radius-mixin($border-radius);
						}
						@if $enable-shadows == true {
							@include shadow-mixin($box-shadow);
						}
					}
				}
				.mm-submenu {
					svg {
						stroke: $primary;
					}
					li {
						a {
							padding: 12px 13px 12px 15px;
						}
					}
				}
				>li {
					&:hover {
						>a {
							color: $primary;
						}
					}
				}
				li.menu-open {
					.mm-arrow-right {
						@include transform-mixin( rotate(90deg));
					}
				}
			}
			li {
				&:hover {
					>.mm-submenu {
						background: $header-submenu-color;
						border: $header-submenu-border-border solid;
						border-color: $header-submenu-border-color;
						display: block;
						@if $enable-transitions == true {
							@include transition-mixin(all 0.3s ease-in-out);
						}
					}
					>.mm-submenu.mm-submenu-data {
						@include opacity-mixin(1);
						@if $enable-transitions == true {
							@include transition-mixin(all 0.3s ease-in-out);
						}
					}
				}
				>.mm-submenu.mm-submenu-data {
					@include opacity-mixin(0);
					visibility: visible;
					left: 100%;
					top: 0;
					transform-origin: left center 0;
				}
				>.mm-submenu {
					.mm-arrow-right {
						margin-right: 0px;
					}
				}
			}
		}
		 .sub-scrll{
            max-height:315px;
			overflow-y: scroll;
			overflow-x: hidden;
        }
	}
.menu-horizontal {
	.mm-sidebar-menu {
		.side-menu {
			li {
				&:hover {
					.mm-arrow-right {
						@include transform-mixin( rotate(90deg));
					}
					li {
						.mm-arrow-right {
							@include transform-mixin( rotate(0deg));
						}
					}
				}
			}
		}
	}
}
}
@media (max-width: 1299px){
    .menu-horizontal {
		position: fixed;
		left: 0;
		right: 0;
		@include opacity-mixin(0);
		top: 0;
		width: 0;
		background: $white;
		height: 100vh;
		z-index: 9;
		overflow-y: scroll;
		overflow-x: hidden;
		@if $enable-transitions == true {
			@include transition-mixin(all 0.3s ease-in-out);
		}
		ul.side-menu {
			display: block !important;
			background: $white;
		}
		.mm-sidebar-menu {
			.side-menu {
				li {
					a {
						padding: 15px 20px 15px 30px;
						&:hover {
							color: $primary;
						}
						
					}
					ul {
						position: static;
						box-shadow: none;
					}
					
				}
				
			}
		}
    }
    .menu-horizontal {
	ul.side-menu {
		li {
			a[aria-expanded=true] {
				.mm-arrow-right {
					@include transform-mixin( rotate(90deg));
				}
			}
		}
	}
}
.sidebar-main {
	.menu-horizontal {
		@include opacity-mixin(1);
		width: 260px;
		@if $enable-transitions == true {
			@include transition-mixin(all 0.3s ease-in-out);
		}
	}
}
}